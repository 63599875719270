const Menu = () => (
    <section className="menu">
                <div className="menu-block">
                    <h3>Beverages</h3>
                    <p className="disclaimer">Our hot beverages come in small (12oz), medium (16oz), and large (20oz). Our cold 
                    beverages come in small (16oz) and large (24oz).</p>
                    <ul className="menu-list">
                        <li>Hot Coffee <strong>$2.75 (S) / $3.3 0 (M) / $3.30 (L)</strong></li>
                        <li>Iced Coffee <strong>$3.30 (S) / $4.13 (L)</strong></li>
                        <li>Hot Tea <strong>$1.93 (S) / $2.20 (M) / $2.48 (L)</strong></li>
                        <li>Iced Tea <strong>$2.58 (S) / $3.19 (L)</strong></li>
                        <li>Hot Cocoa <strong>$2.20 (S) / $2.75 (M) / $3.30 (L)</strong></li>
                    </ul>
                    <p><strong>Coffee Flavors:</strong> Regular, Decaf, Dark Roast, Hazelnut Decaf, Hazelnut, French Vanilla, Maple Cinnamon French Toast, 
                    Pumpkin, Eggnog*</p>
                    <p><strong>Milk Options:</strong> Whole, Skim, Half & Half, Oat, Almond</p>
                    <p><strong>Sweetener Options:</strong> Sugar, Sugar in the Raw, Honey, Splenda, Equal, Sweet 'n Low</p>
                    <p className="disclaimer">*Our "flavor of the month" is subject to change. Please call ahead 
                    if you are looking for a particular flavor.</p>
                </div>
                <div className="menu-block">
                    <h3>Sandwiches</h3>
                    <ul className="menu-list">
                        <li>Egg & Cheese<strong> $4.95</strong></li>
                        <li>Sausage, Bacon, or Ham Egg & Cheese<strong> $6.05</strong></li>
                        <li>Bagel with Topping<strong> $3.85</strong></li>
                        <li>English Muffin with Topping<strong> $2.20</strong></li>
                    </ul>
                    <p><strong>Bread Options:</strong> English Muffin, Wheat English Muffin, Bagel, Croissant ($+1.00), Gluten-Free Bagel ($+0.50), Gluten-Free English Muffin ($+0.50)</p>
                    <p><strong>Topping Options:</strong> Salt, Pepper, Hot Sauce, Ketchup, Mayo, Mustard, Peanut Butter,
                     Jelly, Butter, Cream Cheese</p>
                    <p className="disclaimer">*Our "flavor of the month" is subject to change. Please call ahead 
                    if you are looking for a particular flavor.</p>
                    <h3>Baked Goods</h3>
                    <ul className="menu-list">
                        <li>Muffin <strong>$3.25</strong> (add butter for +$0.50)</li>
                        <li>Donut <strong>$2.75</strong></li>
                        <li>Cruller <strong>$2.83</strong></li>
                        <li>Croissant <strong>$2.75</strong></li>
                    </ul>
                </div>
    </section>
)

export default Menu;